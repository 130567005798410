//
// home.scss
//

@mixin home-common {
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;
}
.bg-home {
    height: 100vh;
    @include home-common();
}
.bg-half-260 {
    padding: 260px 0;
    @include home-common();
}
.bg-half-170 {
    padding: 170px 0;
    @include home-common();
}

.bg-half-80 {
    padding: 80px 0;
    @include home-common();
}

.bg-half {
    padding: 184px 0 90px;
    @include home-common();
}

.bg-auth-home {
    padding: 142px 0;
    @include home-common();
}

//Gradient Home
.bg-circle-gradiant {
    background: radial-gradient(circle at 50% 50%, rgba($primary, 0.1) 0%,
                                                   rgba($primary, 0.1) 33.333%,
                                                   rgba($primary, 0.3) 33.333%,
                                                   rgba($primary, 0.3) 66.666%,
                                                   rgba($primary, 0.5) 66.666%,
                                                   rgba($primary, 0.5) 99.999%);
}

@keyframes animate-left-to-right {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 100% 0;
    }
}

//Saas
.home-dashboard {
    img {
        position: relative;
        top: 60px;
        z-index: 1;
    }

    @media (min-width:768px) {
        &.onepage-hero {
            overflow: hidden;
        }
    }
}

.saas-background {
    background: linear-gradient(360deg, rgba($white, 0) 0%, rgba($primary, 0.2) 100%);
}

//Finance hero
.zoom-image {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.image-wrap {
    width: 100%;
    animation: move 30s ease;
    position: absolute;
}

@keyframes move {
    0% {
        transform-origin: bottom;
        transform: scale(1.0);
    }

    100% {
        transform: scale(1.4);
    }
}

//Coworking Landing / Hotal / Shop
.text-type-element {
    &:after {
        content: "";
        position: absolute;
        height: 12px;
        width: 100%;
        background-color: rgba($primary, 0.15);
        right: 0;
        left: 0;
        bottom: 12px;
        z-index: 0;
        transition: all 0.5s ease;
    }
}

.home-slider {
    .carousel-control-next,
    .carousel-control-prev {
        width: 30px;
        height: 30px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 30px;
        border: 1px solid $gray-400;
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        width: 30%;
    }

    .carousel-fade {
        .carousel-control-next,
        .carousel-control-prev {
            width: 80px;
            height: 80px;
            border: none;

        @media (max-width: 425px) {
            width: 40px;
            height: 40px;
        }
        }
    }

    //Startup
    .carousel-indicators {
        [data-bs-target] {
            width: 8px;
            height: 8px;
            border-radius: 30px !important;
            border: none;
            background: rgba($primary, 0.5);
        }

        .active {
            opacity: 1;
            background: $primary;
            width: 14px !important;
        }
    }
}

.bg-animation-left {
    &:after {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        width: 85%;
        height: 100%;
        background: rgba($primary, 0.7);
        clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);

        @media (max-width: 767px) {
            width: 100%;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
    }
    &.dark-left {
        &:after {
            background: rgba($dark, 0.6);
        }
    }
    &.crypto-home {
        &:after {
            background: $primary;
            width:80%;
            clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
        }
    }
}

//seo agency
.seo-home {
    background:
        radial-gradient(circle at 50% -50%,
            rgba(107,107,107,.08) 0,
            rgba(107,107,107,.08) 33.333%,
            rgba(72,72,72,.08) 33.333%,
            rgba(72,72,72,.08) 66.666%,
            rgba(36,36,36,.08) 66.666%,
            rgba(36,36,36,.08) 99.999%),
        radial-gradient(circle at 0% 0%,
            rgba(184,184,184,.08) 0,
            rgba(184,184,184,.08) 33.333%,
            rgba(96,96,96,.08) 33.333%,
            rgba(96,96,96,.08) 66.666%,
            rgba(7,7,7,.08) 66.666%,
            rgba(7,7,7,.08) 99.999%),
        linear-gradient(45deg,$warning, $primary);
}

//Landing one
.shape-before {
    .shape-img {
        position: absolute;
        top: -50px;
        left: -35px;
        z-index: -1;
        height: auto;
        overflow: hidden;
    }
}

//corporate business
.swiper-slider-hero {
    .swiper-container,
    .swiper-slide .slide-inner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .swiper-container {
        .swiper-button-prev,
        .swiper-button-next {
            background: transparent;
            width: 35px;
            height: 35px;
            line-height: 35px;
            margin-top: -30px;
            top: 98% !important;
            &:before {
                font-family: "Material Design Icons";
                font-weight: 900;
                color: $white;
            }
            &:hover {
                background: $primary;
                border-color: $primary !important;
            }
            &:focus {
                outline: none;
            }
        }
        .swiper-button-prev {
            left: 10px;
            &:before {
                content: "\f0141";
            }
        }
        .swiper-button-next {
            right: 10px;
            &:before {
                content: "\f0142";
            }
        }
    }
    .swiper-pagination-bullet {
        color: $gray-100;
        background: transparent;
    }
    .swiper-pagination-bullet-active {
        color: $white;
    }
    .swiper-container-horizontal
    > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        bottom: 45px;
    }
    .swiper-container-horizontal
    > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 13px;
    }
}

//Comingsoon and Maintenance
.coming-soon, .maintenance, .gradient-text {
    background: linear-gradient(to right, $primary 0%, $danger 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.coming-soon {
    font-size: 100px !important;

    @media(max-width: 768px) {
        font-size: 72px !important;
    }

    @media(max-width: 425px) {
        font-size: 42px !important;
    }
}

// Responsive
@media (min-width: 769px){
    //studio
    .bg-studio {
        &:after{
            content: "";
            position: absolute;
            right: 0px;
            top: 0px;
            width: 50%;
            height: 100%;
            background: $white none repeat scroll 0 0;
            z-index: 2;
        }
    }
}

@media (max-width: 768px) {
    .bg-half {
        padding: 169px 0 50px;
    }
}

@media (max-width: 767px) {
    .bg-home, .bg-half-170, .bg-half-260, .bg-marketing, .swiper-slider-hero .swiper-container .swiper-slide {
        padding: 150px 0;
        height: auto;
    }
}

.partner-logo img.grey-logo {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

.partner-logo img:hover.grey-logo {
    -webkit-filter: grayscale(0);
    filter: none;
}
