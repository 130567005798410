.oauth-buttons {
    .btn {
        padding: 0.5rem 0.7rem;
        margin-bottom: 0.7rem;
        display: block;

        &:before {
            content: '';
            display: inline-block;
            background-repeat: no-repeat;
            background-size: contain;
            width: 1rem;
            height: 1rem;
            transform: translateY(20%);
            padding-left: 0.2em;
        }

        &:hover {
            background-color: white !important;
            color: #1058df !important;
        }
    }

    .btn--google_oauth2 {
        &:before {
            background-image: url("images/oauth/google.svg");
        }
        &:hover:before {
            background-image: url("images/oauth/google_color.svg");
        }
    }
    .btn--seznam_cz {
        &:before {
            background-image: url("images/oauth/seznam_cz_white.svg");
        }
        &:hover:before {
            background-image: url("images/oauth/seznam_cz.svg");
        }
    }
}
